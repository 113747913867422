import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// MUI
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
// MUI Icon

import { dataShopItem } from '@common/data/ShopItem';
import { dataOrder } from '@common/data/Order';
import { ReactComponent as SectionHeaderIcon } from '@common/assets/section_header.svg';
import { ShopItemMasterStock } from '@common/data/ShopItemMaster';
import { ShopItemMaker } from '@common/data/ShopItemMaker';
import { useJudgeSize } from '@common/utils/JudgeSize';
import { userActions } from '@common/data/User';
import { IconButton } from '@mui/material';
// MUI Icon
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { DialogOk } from '@common/components/Dialog';

export function ShopItemDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <ShopItemDetails2 />
    </React.Suspense>
  );
}

const ToMultiLine = ({ body }: { body: string }) => {
  const texts = body.split('\n').map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    );
  });
  return <>{texts}</>;
};

function ShopItemDetails2() {
  const { itemId } = useParams();
  const item = itemId ? dataShopItem.itemMaster.useShopItemMaster(itemId) : undefined;

  return (
    <div className="p-2 pb-24">
      {item ? <ItemDetail item={item} /> : <div>商品が見つかりませんでした。</div>}
    </div>
  );
}

function ItemDetail({ item }: { item: ShopItemMasterStock }) {
  const maker = item?.makerId ? dataShopItem.maker.useShopItemMaker(item.makerId) : undefined;
  const isSp = useJudgeSize();

  return (
    <div className={`${isSp ? '' : 'py-12 px-28'} w-full flex flex-col`}>
      <div className="w-full flex justify-center">
        <img
          alt="商品画像"
          src={item.image[0]}
          className={`w-3/5 ssm:w-full md:w-2/5 object-cover rounded-md`}
        />
      </div>

      <ItemInfo item={item} />

      {maker && <MakerInfo maker={maker} />}
      <AddItemPanel item={item} />
    </div>
  );
}

function ItemInfo({ item }: { item: ShopItemMasterStock }) {
  return (
    <>
      <div className="mt-4 text-sm font-bold">{item.makerName}</div>
      <div className="text-xl font-bold">{item.name}</div>
      <div className="mt-1">
        <span className="text-lg font-bold">{item.price.toLocaleString()}</span>
        <span className="text-xs"> 円（税込）</span>
      </div>
      {item.copy && (
        <div className="mt-2">
          <ToMultiLine body={item.copy} />
        </div>
      )}
      {item.description && (
        <div>
          <hr className="my-4" />
          <div className="flex flex-row items-center">
            <SectionHeaderIcon className="w-4 h-4 mr-2" />
            <span className="font-bold">商品紹介</span>
          </div>
          <div className="mt-2">
            {item.image.length >= 2 && (
              <img
                alt="商品画像2"
                src={item.image[1]}
                className="mr-2 w-1/2 float-left rounded-md"
              />
            )}
            <span>
              <ToMultiLine body={item.description} />
            </span>
          </div>
        </div>
      )}

      {(item.allergen || item.expiration || item.bestByDate) && (
        <div>
          <hr className="my-4" />

          <div className="flex">
            {item.allergen && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【特定原材料】</span>
                <span className="text-sm">{item.allergen}</span>
              </div>
            )}
            {item.expiration && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【消費期限】</span>
                <span className="text-sm">{item.expiration}</span>
              </div>
            )}
            {item.bestByDate && (
              <div className="flex-auto whitespace-nowrap">
                <span className="mr-2 text-sm font-bold">【消味期限】</span>
                <span className="text-sm">{item.bestByDate}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function MakerInfo({ maker }: { maker: ShopItemMaker }) {
  const isSp = useJudgeSize();
  return (
    <div>
      <hr className="my-4" />

      <div className="flex flex-row items-center">
        <SectionHeaderIcon className="w-4 h-4 mr-2" />
        <span className="font-bold">メーカー／ブランド</span>
      </div>
      <div className="mt-2 flex flex-row">
        {maker.image?.[0] && (
          <div className={isSp ? 'w-1/3' : 'w-1/4'}>
            <img alt="店舗画像" src={maker.image[0]} className="mr-2 w-full rounded-md" />
          </div>
        )}
        <div className="grow flex flex-col pl-2">
          <div className="font-bold">{maker.makerName}</div>
          {maker.businessHours && (
            <div className="flex text-sm">
              <div className="w-16">営業時間</div>
              {maker.businessHours}
            </div>
          )}
          {maker.holiday && (
            <div className="flex text-sm">
              <div className="w-16">定休日</div>
              {maker.holiday}
            </div>
          )}
          {maker.phone && (
            <div className="flex text-sm">
              <div className="w-16">電話番号</div>
              {maker.phone}
            </div>
          )}
          {maker.postalCode && maker.address && (
            <div className="text-sm">
              〒{maker.postalCode} {maker.address}
            </div>
          )}
          {maker.url && (
            <div className="text-sm">
              <a href={maker.url}>{maker.url}</a>
            </div>
          )}
        </div>
      </div>
      {maker.description && <div className="mt-2">{maker.description}</div>}
    </div>
  );
}

// showError 関係は選択できないようにしたので消して良いはず
function AddItemPanel({ item }: { item: ShopItemMasterStock }) {
  const [count, setCount] = useState<number>(1);
  const addCartItem = dataOrder.cart.useAddCartItem();
  const availableByStock = dataOrder.cart.useItemAvailableCountByStock(item.itemId);
  const maxItemsAddableCartSize = dataOrder.cart.useItemAvailableCountByCartSize(item.itemId);
  const maxItemsAddableByWeight = dataOrder.cart.useItemAvailableCountByCartWeight(item.itemId);
  const maxCartItemsAddableByCount = dataOrder.cart.useItemAvailableCountByCount(item.itemId);
  //const firstDeliveryTimeOption = dataOrder.deliveryTime.useDeliveryFirstAvailableTimeOption();
  const deliveryDateTodayFirstAvailableTimeOption =
    dataOrder.deliveryTime.useDeliveryDateTodayFirstAvailableTimeOption();
  // const [showAdded, setShowAdded] = React.useState(false);
  const isLogin = userActions.useIsLogin();
  const navigate = useNavigate();
  const [openLoginConfirm, setOpenLoginConfirm] = React.useState(false);

  // const [showError, setShowError] = React.useState(false);

  const onClickAddCart = () => {
    if (isLogin) {
      addCartItem(item.itemId, count).then((ret) => {
        if (ret) navigate(`/cart/${item.itemId}/${count}`);
        // else
        //   setShowError(true);
      });
    } else {
      setOpenLoginConfirm(true);
    }
  };

  // ★snackbarが表示されているときにカートに追加すると表示が消えてしまうのを修正
  // pb:8 (1+7) は MyBottomNavigation に隠れる高さ
  return (
    <Slide direction="up" in>
      <div
        className="fixed bottom-0 left-0 right-0 p-2 pb-20 ssm:pb-16 flex flex-row items-center 
        text-primaryContrast"
      >
        <DialogOk
          open={openLoginConfirm}
          title={'カートに追加できませんでした'}
          message={'サービスのご利用にはログインまたは会員登録が必要です。'}
          textOk="ログイントップへ"
          callbackOk={() => navigate('/login')}
        />
        {availableByStock <= 0 ? (
          <div
            className="w-full py-3 rounded-full 
          text-center text-2xl font-bold text-white bg-gray-300"
          >
            在庫がありません
          </div>
        ) : maxItemsAddableCartSize <= 0 ? (
          <div
            className="w-full py-3 rounded-full 
          text-center text-2xl font-bold text-white bg-gray-300"
          >
            配達容量が上限に達しています
          </div>
        ) : maxItemsAddableByWeight <= 0 ? (
          <div
            className="w-full py-3 rounded-full 
          text-center text-2xl font-bold text-white bg-gray-300"
          >
            配達重量が上限に達しています
          </div>
        ) : maxCartItemsAddableByCount <= 0 ? (
          <div
            className="w-full py-3 rounded-full
          text-center text-2xl font-bold text-white bg-gray-300"
          >
            配達総数が上限に達しています
          </div>
        ) : !deliveryDateTodayFirstAvailableTimeOption ? (
          <div
            className="w-full py-3 rounded-full
            text-center text-l font-bold text-white bg-gray-300"
          >
            営業開始の１時間前よりご注文できます
            <br />
            次回営業日はトップページをご確認ください
          </div>
        ) : (
          <div className="w-full flex flex-col items-center bg-white">
            <div className="flex items-center">
              <IconButton
                color="primary"
                sx={{ fontSize: 40 }}
                onClick={() => setCount(count - 1)}
                disabled={count === 1}
              >
                <RemoveCircleRoundedIcon fontSize="inherit" />
              </IconButton>
              <p className="mx-2">
                <span className="text-2xl font-bold text-black">{count}</span>
              </p>
              <IconButton
                color="primary"
                sx={{ fontSize: 40 }}
                onClick={() => setCount(count + 1)}
                disabled={
                  count + 1 > availableByStock ||
                  count + 1 > maxItemsAddableCartSize ||
                  count + 1 > maxItemsAddableByWeight ||
                  count + 1 > maxCartItemsAddableByCount
                }
              >
                <AddCircleRoundedIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div
              className="w-full py-3 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-white bg-primary"
              onClick={onClickAddCart}
            >
              カートに追加
            </div>
          </div>
        )}
        {/* <ShowMessage
          show={showAdded}
          onClose={() => setShowAdded(false)}
          item={item}
          count={count}
        /> */}
        {/* <ShowError show={showError} onClose={() => setShowError(false)} /> */}
      </div>
    </Slide>
  );
}

// const ShowMessage = ({
//   show,
//   onClose,
//   item,
//   count,
// }: {
//   show: boolean;
//   onClose: () => void;
//   item: ShopItemMaster;
//   count: number;
// }) => {
//   const isSp = useJudgeSize();

//   return (
//     <Snackbar
//       open={show}
//       autoHideDuration={5000}
//       onClose={onClose}
//       sx={{
//         bottom: 70,
//       }}
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//     >
//       <div
//         className={`${isSp ? '' : 'mb-12'} p-2 pr-3 rounded text-secondaryContrast bg-secondary`}
//       >
//         <CheckCircleOutlineRoundedIcon className="pb-1 mr-1" />
//         {item.name}が{count}個追加されました
//       </div>
//     </Snackbar>
//   );
// };

// const ShowError = ({show, onClose}: {show:boolean, onClose: () => void}) => {
//   return (
//     <Snackbar
//       open={show}
//       autoHideDuration={5000}
//       onClose={onClose}
//       sx={{ bottom:{xs: 64} }}
//       className="w-fit"
//     >
//       <Paper className="p-2 pr-3"
//         sx={{color: "secondary.contrastText", bgcolor: "secondary.main"}}>
//         <WarningAmberRoundedIcon className="pb-1 mr-1"/>
//         カートがいっぱいです
//       </Paper>
//     </Snackbar>
//   );
// };
